<template>
  <div class="market-choice buy-page sell-page">
    <div class="title-md">
      {{ $t("sell-char-title") }}
    </div>

    <div class="market-choice__text">
      {{ $t("sell-char-notice") }}
    </div>

    <CharCard
      :character-name="characterName"
      :nft-id="nftId"
      :character-image="characterImage"
    />

    <div class="page-input" :class="{ error: errorAmount }">
      <input
        type="number"
        readonly
        :min="min"
        :max="max"
        v-model="amount"
        class="input"
        :placeholder="$t('input-amount')"
      />
      <div class="caret"></div>
      <div class="page-input__icon">
        <CoinGoldSvg />
        SUB
      </div>
      <div class="page-input__footer">
        <div class="error-msg">
          {{ errorAmount }}
        </div>
        <div class="page-input__notice">
          <span>Min: {{ min }} SUB</span
          ><span>Max: {{ formatNumber(max, true) }} SUB</span>
        </div>
      </div>
    </div>

    <button
      class="game-btn"
      @click="putCshForSale"
      :disabled="!amount || !isValidKeyboard"
    >
      <span v-if="!amount || !isValidKeyboard"> {{ $t("sell-btn") }} </span>
      <span v-else> {{ $t("sell-for-btn") }} SUB {{ amount }}</span>
    </button>

    <Keyboard @input="makeKeyboardInput" @clear="clearInput" />
  </div>
</template>

<script>
import CharCard from "../../components/character/CharCard";
import CoinGoldSvg from "../../components/icons/CoinGoldSvg";
import Keyboard from "../../components/utils/Keyboard";
import contract from "../../api/contract";
import api from "../../api/api";
import loadShoppieByIdMixin from "../../mixins/loadShoppieByIdMixin";
import FormatNumberMixin from "../../mixins/common/FormatNumberMixin";
import UseKeyboardMixin from "../../mixins/keyboard/useKeyboardMixin";
import SetProcessingMixin from "../../mixins/general/SetProcessingMixin";
import successes from "../../utils/successes";
import errors from "../../utils/errors";
export default {
  name: "SellChar",
  mixins: [
    loadShoppieByIdMixin,
    FormatNumberMixin,
    UseKeyboardMixin,
    SetProcessingMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    async putCshForSale() {
      try {
        this.setLoading(true);
        const [error, signatures] = await contract.sellCsh(
          this.nftId,
          +this.amount
        );
        if (error) {
          this.setError({ message: error?.message || errors.UNKNOWN_ERROR });
          return;
        }

        if (signatures) {
          await api.putCshForSale({
            cryptoshoppieId: this.id,
            price: this.amount,
            signatures: signatures,
          });
          this.setSuccess(successes.CSH_IS_ON_MARKETPLACE);
        }
      } catch (e) {
        this.setError({ message: e.response.data.error.message });
      } finally {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.getCryptoshoppiesById(this.id);
    this.min = 5;
  },
  components: { Keyboard, CoinGoldSvg, CharCard },
};
</script>

<style scoped></style>
