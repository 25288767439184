import FormatNumberMixin from "../common/FormatNumberMixin";

export default {
  mixins: [FormatNumberMixin],
  data: () => ({
    amount: "",
    errorAmount: null,
    min: 0,
    max: 100,
    isValidKeyboard: true,
  }),
  methods: {
    validate() {
      if (parseFloat(this.amount) < this.min) {
        this.errorAmount = `Min number is ${this.formatNumber(this.min, 2)}`;
        this.isValidKeyboard = false;
        return;
      }
      if (parseFloat(this.amount) > 100) {
        this.errorAmount = `Max number is ${this.formatNumber(
          this.max,
          2
        )} SUB`;
        this.isValidKeyboard = false;
        return;
      }
      this.isValidKeyboard = true;
    },
    makeKeyboardInput(val) {
      this.errorAmount = null;
      this.amount = this.amount.toString() + val.toString();
      this.validate();
    },
    clearInput() {
      this.errorAmount = null;
      this.amount = this.amount.substring(0, this.amount.length - 1);
      this.validate();
    },
  },
};
